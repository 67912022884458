.brandcard {
  width: 250px !important;
  background-color: #F8F6ED !important;
  /* padding: 10px !important; */
}
.brandcardfont {
  font-size: 18px !important;
  /* width: 250px !important; */
  text-align: center !important;
  
}


.bannerafternavbar
{
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 1000px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .bannerafternavbar {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.search-bar-popper {
  width: 400px !important;
}

.landing-page-tag-spacing{
  margin-left: 80px;
  margin-right: 80px;
}

@media (max-width: 1000px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .landing-page-tag-spacing{
    margin-left: 0px;
    margin-right: 0px;
  }
}





/* Add this to your LandingPage.css file */
.sublist {
  display: none;
  /* position: absolute; */
  /* top: 0; */
  /* left: 100%; Adjusted to display the sublist on the right */
  background-color: white;
  border: 1px solid #ccc;
  /* background-image: url('https://ik.imagekit.io/vhfsx9xkeh/51b0dc3495d6c4b4fbd95d83189abf93.png?updatedAt=1712280463325'); 
  background-repeat: no-repeat;
  background-size: fill;  */
  padding: 5px;
  /* z-index: 999; Ensures the sublist appears on top */
  /* width: max-content; Set width to expand to fit content */
  width: 445px; /* Set width as desired */
  text-wrap: nowrap;
  height: 383px;
}

@media (max-width: 720px) {
  .sublist {
   width: 340px;
   height: 180px;
    
  }
}

@media (max-width: 443px) {
  .sublist {
   width: 240px;
   height: 120px;
    
  }
}



.banner-card-main-text {
  font-size: 13px !important;
}
.banner-card-secondary-text {
  font-size: 11px !important;
}
@media (max-width: 720px) {
  .banner-card-main-text {
    font-size: 8px !important;
    
  }
  .banner-card-secondary-text {
    font-size: 7px !important;
    margin-bottom: 1px !important;
    
  }
  .banner-search-card {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .search-field-banner {
    margin-top: 0px !important;
  }
  .search-bar-popper {
    width: 200px !important;
  }
  
}





.list-group-item {
  font-size: 13px !important;
  transition: background-color 0.3s ease; /* Add transition effect */
}


.list-group-item:hover {
  background-color: #FBE9E8; /* Change background color on hover */
}

@media (max-width: 720px) {
  .list-group-item {
    font-size: 8px !important;
    
  }
}

.sublist-headings {
  font-size: 13px !important;
}
.sublist-sub-headings {
  font-size: 10px !important;
}

@media (max-width: 720px) {
  .sublist-headings {
    font-size: 8px !important;
    
  }
  .sublist-sub-headings {
    font-size: 6px !important;
    
  }
}








.list-group-item:hover .sublist {
  display: block;
}




.sublist-container {
 
}








#proudoloprint-1 {
    
}
.custom-input {
    height: 60px; /* Adjust as needed */
    color: black;
}

.custom-button {
    height: 60px; /* Adjust as needed */
}
.custom-input::placeholder {
    color: rgb(189, 183, 183); /* Change placeholder color to black */
    font-style: italic;
    font-weight: 500;
}



.bottombannar {
  background-color: rgba(248, 246, 237, 1) !important 
}





.category {
  cursor: pointer;
}

.subcategory-list {
  position: absolute;
  left: -150px; /* Adjust this as per your design */
  top: 0;
  background-color: #f2f2f2; /* Change the background color as needed */
  padding: 5px;
  border: 1px solid #ccc; /* Add border or remove as needed */
  display: none;
}

.subcategory:hover .subcategory-list {
  display: block;
}

.subsubcategory-list {
  position: absolute;
  right: -150px; /* Adjust this as per your design */
  top: 0;
  background-color: #e0e0e0; /* Change the background color as needed */
  padding: 5px;
  border: 1px solid #ccc; /* Add border or remove as needed */
  display: none;
}

.subsubcategory:hover .subsubcategory-list {
  display: block;
}










.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
}

.categories-list {
  list-style-type: none;
  padding: 0;
}

.category-item {
  margin-bottom: 15px;
  cursor: pointer;
}

.subcategories-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.category-item:hover .subcategories-list {
  display: block;
}










.salecolor {
    color: #bb0606;
    font-style: italic;
  }
  .main {
    background-color: #071A44 !important;
    height: 360px;
    background-image: url('banar.jpg');
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 777px) {
    .main {
      
      /* background-image: none; */
      /* background-color: white !important; */
      /* justify-content: center; */
    }
  }
  .smatcard {
    font-size: 15px;
    font-weight: 400;
    color: #0f0f0f ;
  }
  
  
  
  .kh {
    width: 130px;
  }
  
  .imgslider {
    border: 2px solid #bb0606;
    border-radius: 5px;
     /* Ensure the image fills the container width */
    /* Ensure the image fills the container height */
    object-fit: contain; /* Fit the entire image within the container without cropping */
  
  }
  
  .bul {
    justify-content: center;
    max-width: 105%;
    height: 13vh;
    display: block;
  }
  
  @media (max-width: 768px) {
    /* Styles for smaller screens */
    .bul {
      justify-content: center;
      max-width: 105%;
      height: 50vh;
      display: block;
    }
  }
  
  /* ------------------------local */
  
  /* Default styles for larger screens */
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 120%;
  }
  
  /* Media query for smaller screens (e.g., phones) */
  @media (max-width: 768px) {
    .card-deck {
      margin-right: 0;
      margin-left: 0;
      flex-direction: column;
      /* Stack cards vertically */
      align-items: center;
      /* Center-align cards */
      width: 50%;
    }
  
    .card {
      margin-bottom: 20px;
      /* Add some space between cards */
    }
  }
  
  .input-group {
    margin-top: 20px;
    /* Set a maximum width */
    
  }
  
  /* Media query for smaller screens (e.g., phones) */
  @media (max-width: 576px) {
    .search-box {
      width: 90%;
      /* Adjust width for smaller screens */
      height: auto;
      /* Allow height to adjust based on content */
      max-width: none;
      /* Remove maximum width */
      padding: 0 10px;
      /* Add padding as needed */
      justify-content: center;
      /* Center content horizontally */
      align-items: center;
      /* Center content vertically */
    }
  }
  
  .search-btn {
    background-color: transparent;
    font-size: 18px;
    
    right: 56px;
    border: none;
    color: #6c6c6c;
    
  }
  
  .search-btn:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: black;
  }
  
  .col p {
    margin: 0;
    /* or padding: 0; */
  }
  
  .search-btn:focus {
    outline: none;
    color: black;
  }
  
  .search-input {
    padding-left: 50px;
    /* Adjust the padding as needed */
    margin-left: 100px;
  }
  
  #imgstyle {
    width: auto;
    height: auto;
  }
  
  .navbar-toggler-icon {
    background-color: #000000;
    /* Set the color to black */
  }
  a {
    text-decoration: none !important;
    /* Remove underline */
  }
  
  .search-box {
    display: flex;
    align-items: center;
  }
  
  .search-input {
    flex: 1;
    margin-left: 10px;
    /* Adjust the spacing between icon and input */
  }
  
  .about-link h5 {
    text-decoration: none !important;
    color: #000;
    /* Set initial text color */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color change */
  }
  
  .about-linkk h5 {
    color: #000;
    /* Set initial text color */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color change */
  }
  
  .smcarde {
    width: 1000px;
  }
  
  .u {
    width: 100%;
  }
  
  .bordered-image {
    border-radius: 5px;
    border: 3px solid black;
    /* Adjust the size and color as needed */
    padding: 10px;
    /* Optional: Adds padding inside the border */
  }
  
  
  
  /* Adjust search box on smaller screens (phones) */
  @media (max-width: 576px) {
    .search-box {
      margin-left: 0;
      text-align: center;
      /* Center-align contents on smaller screens */
    }
  
    .smcarde {
      /* display: flex; */
      justify-content: center;
      align-items: center;
      width: 300px;
      /* margin-left: 50px; */
    }
  
    .tit {
      color: #ffffff;
      font-size: 20px;
    }
  
    @media (max-width: 768px) {
      .about-link {
        position: static;
        /* Reset position */
        margin-left: auto;
        /* Set margin-left to auto to push to the right */
        display: block;
        /* Ensure it's a block-level element */
        text-align: right;
        /* Align the content to the right */
      }
  
      .uk {
        margin-top: -50px;
        /* Example of setting a negative margin on the top side */
      }
  
      .ma {
        margin-top: 30px;
      }
    }
  }
  
  .hh {
    width: 130%;
  }
  
  @media (max-width: 767px) {
    .gx-5 {
      flex-wrap: wrap;
      /* Allow items to wrap onto new lines */
      width: 100%;
      /* Adjust width to fit the screen */
    }
  
    .hh {
      width: 100%;
    }
  
    .col {
      flex: 0 0 100%;
      /* Make each column take up the full width */
      max-width: 50%;
      /* Ensure columns don't exceed the screen width */
      margin-bottom: 20px;
      /* Add some space between columns */
    }
  }
  .search-input {
    width: 200% !important;
  }
  .bu {
    width: 190%;
    background-color: #ffffff !important;
    border: none;
    color: #000;
  }
  .itemtext {
    font-size: 4vw;
  }
  .smatcard {
    /* Limit text to two lines */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .searchcard {
   
    background-color: white;
  }
  .sadsad {
    background-color: #F8F8F8 !important;
  }
  .card{
    margin: 5% 0%;
  }
  
  .card-body{
    margin: 0% 0% 0% 3%;
    padding: 6% 0%;
  }
  
  

  .seven h1 {
    text-align: center;
        font-size:30px; font-weight:300; color:#222; letter-spacing:1px;
        text-transform: uppercase;
    
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        grid-template-rows: 27px 0;
        grid-gap: 20px;
        align-items: center;
    }
    
    .seven h1:after,.seven h1:before {
        content: " ";
        display: block;
        border-bottom: 1px solid #c50000;
        border-top: 1px solid #c50000;
        height: 5px;
      background-color:#f8f8f8;
    }

    .tick-circle {
      width: 20px; /* Set the width and height to create a circle */
      height: 20px;
      border-radius: 50%; /* Make it round */
      background-color: darkgreen; /* Set the background color to dark green */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .tick {
      color: white; /* Set the tick color to white */
      font-size: 10px; /* Adjust the tick size */
    }
    .shopbutton {
      background: none;

      border: none;

      text-align: initial;
      color: #ffffff;
    }
    .brandsbanner {
      width: 100% !important;
    }
    .brandsbackground {
      
      width: 100% !important;
    }


/* ACCESSORIES */












/* .product-slider {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.product-slider::-webkit-scrollbar {
  display: none; 
}

.product-card {
  flex: 0 0 auto; 
  background: linear-gradient(to bottom, yellow 70%, gray 70%);
  border-radius: 15px;
  padding: 20px;
  margin-right: 10px;
  width: 200px; 
}

.product-info {
  margin-bottom: 10px;
}

.product-info h3 {
  margin: 0;
}

.product-info button {
  background-color: blue; 
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.product-image img {
  width: 100%;
  height: auto;
}

.carousel {
  width: 100%;
}

.carousel-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.carousel-item {
  display: flex;
  justify-content: center;
} */





  
/* .product-slider-container {
  position: relative; 
  overflow-x: auto; 
}

.navigation-icons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.navigation-icons button {
  margin: 0 10px;
} */




/* .product-slider-container {
  position: relative;
  overflow-x: auto;
}

.product-slider-row {
  display: flex;
}

.product-card {
  margin: 10px;
  padding: 20px;
  background: linear-gradient(to right, yellow 70%, gray 70%);
  border-radius: 20px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.product-image {
  margin-bottom: 20px;
}

.product-image img {
  width: 100%;
  border-radius: 10px;
}

.product-details {
  margin-bottom: 10px;
}

.product-details h3 {
  margin: 0;
}

.product-details button {
  margin-top: 10px;
}

.navigation-icons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.navigation-icons button {
  margin: 0 10px;
}
 */



 .product-slider-container {
  position: relative;
  overflow-x: auto;
}

.product-slider-row {
  display: flex; /* Make it a flex container */
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  justify-content: space-between; /* Distribute items evenly along the main axis */
}

.product-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;
  background: linear-gradient(to right, #F7DB03 75%, #F3F3F3 25%);
  width: calc(50% - 20px); /* Set width to half minus margin */
  max-width: 600px; /* Set maximum width */
  height: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* position: relative; */
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px; /* Adjust the width as needed */
  background: #F3F3F3;
  border-radius: 0 20px 20px 0;
}

.product-image {
  /* margin-bottom: 20px; */
  display: flex !important;
  justify-content: end !important;
}

.product-image img {
  display: flex !important;
  justify-content: end !important;
  width: 55% !important;
  border-radius: 10px;
}

.product-details {
  margin-bottom: 10px;
}

.product-details h3 {
  margin: 0;
}

.product-details button {
  margin-top: 10px;
}
.navigation-icons {
  position: absolute;
  bottom: 0px;
  left: 50%;
  
  transform: translateX(-50%);
}

.navigation-icons button {
  margin: 0 10px;
}

.sublist-headings:hover {
  color: #E75480 !important;
}
.sublist-sub-headings:hover {
  color: #E75480 !important;
}

