body {
  font-family: Roboto, sans-serif;
  
}


/* Hide the magnifier by default */
.magnifier-container .magnifier-preview {
  display: none;
}

/* Show the magnifier on hover */
.magnifier-container:hover .magnifier-preview {
  display: block;
}


.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  margin: auto;
}

#myCarousel {
  max-width: 650px;
  margin: 0 auto;
  background: #fff;
}

#thumbCarousel {
  max-width: 650px;
  margin: 0 auto;
  overflow: hidden;
  background: #fff;
  padding: 10px 0;
}

#thumbCarousel .thumb {
  float: left;
  margin-right: 10px;
  border: 1px solid #e6e6e6;
  background: #fff;
}

#thumbCarousel .thumb:last-child {
  margin-right: 0;
}

.thumb:hover {
  cursor: pointer;
}

.thumb img {
  opacity: 0.5;
}

.thumb img:hover {
  opacity: 1;
}

.thumb.active img {
  opacity: 1;

}


.form-check-label{
  position:relative;
  top:2px;
  left:5px;
}

label{
  
  margin-top: -12px;
}

.text-new{
  margin-top: -5px;
  margin-left: 10px;
}
.btn-primary {
  border-color: #DB241B;
  
}
.text-primary {
  background-color: #DB241B;
}

.active-tab {
  /* background-color: #fff; Change to the color you prefer */
  border-bottom: 2px solid rgb(0, 0, 0); /* Change to the style you prefer */
  font-weight: bold; /* Change to the style you prefer */
  /* Add any other styles you want for the active tab */
}

