.trade-signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .trade-signin-form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgrey;
    width: 300px;
  }
  
  .trade-signin-form {
    width: 100%;
  }
  
  .trade-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .trade-signin-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .trade-signin-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .trade-signin-button:hover {
    background-color: #0056b3;
  }
  
  /* p {

    text-align: center;
  }
   */
  p a {
    color: #007bff;
    text-decoration: none;
  }
  
  p a:hover {
    text-decoration: underline;
  }
  