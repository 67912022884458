.bcolor {
    background-color: rgb(252, 251, 251);
    border-radius: 30px; /* Adjust this value to change the roundness of the corners */
}
.bccolor{
    background-color: rgb(255, 255, 255);
    border-radius: 30px; /* Adjust this value to change the roundness of the corners */
    width: 198%;
    margin-left: -13px;
}
h5{
    font-size: 17px;
}
