
  
  .custom-radio .form-check-input {
    margin: '2px';
    background-color: #D2D2D2; /* Change to desired color */
    border-color: #2A2B2E; /* Change to desired color */
    
    
  }
  
  .custom-radio .form-check-input:checked {
    background-color: #DB241B; /* Change to desired color */
    border-color: #2A2B2E; /* Change to desired color */
  }

 

 
  
  