.app {
  
  position: relative;
}

header {
  background-color: #333;
  padding: 10px;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.hamburger-icon {
  cursor: pointer;
}

.line {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
}

.sidebar {
  position: absolute;
  padding: 0px !important;
  box-shadow: none !important;
  width: 25% !important;
  top: 100%;
  left: 17%;
  transform: translateX(-50%);
  /* width: 200px; */
  background-color: #ffffff !important;
  /* z-index: 555 !important; */
  /* Other styles for the sidebar */
}












.topnavlinks {
  padding-left: 80px;
  padding-right: 80px;
} 

@media (max-width: 1000px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .topnavlinks {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 350px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .responsive-text {
    font-size: 10px !important;
  }
  .responsive-text-container {
    justify-content: space-between !important;
  }
}

.navbar-padding {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

@media (max-width: 1000px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .navbar-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.navbar-logo {
  width: 100px;
  height: auto;
}

.navbar-toggler-icon {
  /* background-color: 'none' !important; */
  /* background: transparent !important; */
  background-color: #fff !important;
  
}

@media (max-width: 1000px) {
  /* CSS rules to apply below 1000px */
  /* For example: */
  .navbar-logo {
    width: 100px;
    height: auto;
  }
}

 
 
 
 
 Body Styles
.topbanner {
  width: 100%;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

#search {
 height: 3px;
 text-align: center;
}

.ic.menu {
  background: none !important;
  position: fixed;
  top: 90px !important;
  width: 50px !important;

}
.vr {
  height: 15px;
}
header.dark blockquote { color:#fff; }
header.light blockquote { color:#000; }


blockquote h2 { padding-right: 40px; margin: 0px; }
header.dark blockquote a {color: orange; text-decoration: underline;}
header.light blockquote a {text-decoration: underline;}

header.dark { background-color:white; }
header.light { background-color: #fff; }


nav { position: relative;  }
header.dark nav { background-color:black }
header.light nav { background-color:rgba(0,0,0,0.5); }


.image-container img {
  max-width: 100%;
  height: auto;
}

#search {
 height: 3px;
 text-align: center;
}

.vr {
  height: 20px;
}

a { text-decoration: none; }
ul.main-nav { 
  list-style-type: none; 
  padding: 0px;
  font-size: 0px;
  max-width: 1000px;
  margin: 0 auto;
}

ul.main-nav > li { 
  display: inline-block;
  padding: 0; 
}

ul.main-nav > li > a { 
  display: block; 
  padding: 20px 30px; 
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}

ul.main-nav > li:hover { background-color: #f9f9f9; }
ul.main-nav > li:hover > a { color: #333; font-weight: 400; }

ul.main-nav > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type : none;
  display:block;
}

ul.main-nav > li ul.sub-menu-lists > li {
  padding: 2px 0;
}

ul.main-nav > li ul.sub-menu-lists > li > a {
  font-size: 14px;
}

.ic {
  position: fixed; 
  cursor: pointer;

  display: inline-block;
  right: 25px;
  width: 32px;
  height: 24px;
  text-align: center;
  top:0px;
  outline: none;
}

.ic.close { 
  opacity: 0; 
  font-size: 0px; 
  font-weight: 300; 
  color: #fff;
  top:8px;
  height:40px;
  display: block;
  outline: none;
}


.ic.menu { top:25px; z-index : 20; }

.ic.menu .line { 
  height: 4px; 
  width: 100%; 
  display: block; 
  margin-bottom: 6px; 
}
.ic.menu .line-last-child { margin-bottom: 0px;  }

.sub-menu-head { margin: 10px 0; }
.banners-area { margin-top: 20px; padding-top: 15px; }


@media only screen and (max-width:768px) {
  .sub-menu-head { color:orange; }
  .ic.menu { display: block; }
  header.dark .ic.menu .line { background-color: #DB241B; } 
  header.light .ic.menu .line { background-color: #000; }
  .ic.menu .line {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }
  .ic.menu:focus .line { background-color: #fff !important; }
  
  .ic.menu:focus .line:nth-child(1) { 
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
  }
  
  .ic.menu:focus .line:nth-child(2) { 
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); 
    margin-top: -10px;
  }
  
  .ic.menu:focus .line:nth-child(3) {
    transform: translateY(15px);
    opacity: 0;
  }
  
  .ic.menu:focus{ outline: none; }
  .ic.menu:focus ~ .ic.close { opacity: 1; z-index : 21;  outline: none;  }
  
 
  .ic.menu:focus { opacity: 1;  }
  

  nav { background-color: transparent; }
  
 
  ul.main-nav {
    z-index:2; 
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color:rgba(0,0,0,1);
    height: 100%;
    overflow: auto;
    
   -webkit-transition-property: background, width;
    -moz-transition-property: background, width;
    -o-transition-property: background, width;
    transition-property: background, width;
       -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }
  
  .ic.menu:focus ~ .main-nav { width: 300px; background-color:rgba(0,0,0,1); }
  
  ul.main-nav > * { 
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
       -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  .ic.menu:focus ~ .main-nav > * {opacity: 1;}
  
  ul.main-nav > li > a:after {display: none;}
  ul.main-nav > li:first-child { border-radius: 0px; }
  ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444;
  }
  
  ul.main-nav > li > a { font-weight: 600; }
  
  ul.main-nav > li ul.sub-menu-lists > li a { color: #eee; font-size: 14px; }
  .sub-menu-head { font-size: 16px;}
  ul.main-nav > li:hover { background-color: transparent;  }
  ul.main-nav > li:hover > a {color: #fff; text-decoration: none; font-weight: 600;}
 .ic.menu:focus ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1.0;
  }
  
  .sub-menu-block { padding: 0 30px; }
  .banners-area { padding-bottom: 0px;  }
  .banners-area div { margin-bottom: 15px;  }
  .banners-area { border-top: 1px solid #444; }
}

@media only screen and (min-width:769px) {
  .ic.menu { display: none; }
  
  ul.main-nav { display: block; position: relative; }
  .sub-menu-block { padding: 15px; }
  
  
  ul.main-nav > li > div.sub-menu-block { 
  visibility: hidden;
  background-color: #f9f9f9;
  position: absolute;
  margin-top: 0px;
  width: 100%;
  color: #333;
  left: 0;
  box-sizing: border-box;
  z-index : 3;
  font-size: 16px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  opacity: 0;
    

  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  
  }
  
  ul.main-nav > li:hover > div.sub-menu-block{ 
    background-color: #f9f9f9; 
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  
  ul.main-nav > li > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
       -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  
  ul.main-nav > li:hover > div.sub-menu-block > * {
    opacity: 1;
  }
  
  .sub-menu-head { font-size: 20px;}
  

  header.dark ul.main-nav > li > a { border-right: 1px solid 'none'; }
  header.light ul.main-nav > li > a { border-right: 1px solid #666; }
  
  ul.main-nav > li > a:after {
    content: '';
    width: 1px;
    height: 62px;
    position: absolute;
    right:0px;
    top: 0px;
    z-index : 2;
  }
  header.dark ul.main-nav > li > a:after { background-color: 'none'; }
  header.light ul.main-nav > li > a:after { background-color: #999; }
  
  
  ul.main-nav > li > a.mega-menu > span { display: block; vertical-align: middle; }
  ul.main-nav > li > a.mega-menu > span:after {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    content: '';
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }

  ul.main-nav > li:hover > a.mega-menu span:after{
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 5px solid #666;
  }
  .banners-area { border-top: 1px solid #ccc; }
}
#topnavbar {
  
}
@media screen and (max-width: 660px) {
  #navigationlinks {
    
  
  }
  #myid {

  
  }

  

}


/* .search-bar-lg {
  width: 700px !important; 
} */

.search-bar-lg {
  width: 500px !important;
}

@media (max-width: 1273px) {
  
  .search-bar-lg {
    width: 450px !important;
  }
 
}

@media (max-width: 992px) {
  
  .search-bar-lg {
    width: 450px !important;
  }
  .cart-item-icon {
    margin-right: 15px;
    margin-top: 15px;
  }
  .login-credential-buttons {
    width: 40%;
    margin-top: 5px !important;
  }
}

@media (max-width: 515px) {
  
  .search-bar-lg {
    width: auto !important;
  }
  .cart-item-icon {
    margin-right: 15px;
  }
  .login-credential-buttons {
    width: 40%;
    margin-top: 5px !important;
    
  }
}

.cart-item-icon {
  margin-right: 15px;
}

.cart-icon-lg .bi-cart {
  font-size: 1.5rem; 
}






/* FOR NEW NAVBAR */
.hover-sublist:hover{
  color: #f8827c;
}







 
