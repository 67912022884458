/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"/> */

/* Imports */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&display=swap');

/* Define variables */






/* Change selection Color */


.allpage {
  display: flex;
}

/* Remove margins and paddings on every element initally */
* {
  margin: 0px;
  padding: 0px;
}

/* .sidebar
{
  height:100vh;
  width:250px;
  background-color:#252627;
} */

.menu {
  user-select: none;
  display: flex;
  justify-content: space-around;
  width: 20vw !important;
  height: 7vh !important;
  display: flex;
  align-items: center;
  color: #FFFFFF !important;
  background: #071A44 !important;

  transition-duration: 0.3s;
}

.menu:hover {

  cursor: pointer;
}




/* Container for all Pages */


/* Container for single page */
.page {
  width: calc(100% - 250px);
  padding: 50px;
  animation: fadein 0.3s 1 ease-out;
}

/* Style for permalinks */
a {
  color: var(--main-col);
}

/* Fade in animation */
@keyframes fadein {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
  }
}

/* Add margin to every element in a page */
.page * {
  /* margin: 10px; */
  padding-top: 0;
  /* Remove padding on the top side */
}

.ali {
  height: 430px !important;
}

.menu * {

  transform: translateX(0px);
  transition-duration: 0.3s;
}

/* Translate the menu text to the right on hover */
.menu:hover>* {
  transition-duration: 0.3s;
  transform: translateX(20px);
}

/* Disable and enable arrow on hover */
.menu>i {
  opacity: 0;
}

/* Regular styles */

/* Media query for phones */


/* --------------------------------GenderPayGap----------------- */
@media only screen and (max-width: 600px) {

  /* Adjust styles for smaller screens */
  #GenderPayGap {
    /* For example, you can modify the layout or width of the content */
    width: 100%;
    /* Ensure it takes the full width of the screen */
  }

  .kh {
    width: 50%;
  }

  #GenderPayGap h1 {
    /* Adjust heading styles for smaller screens */
    font-size: 24px;
    /* Modify font size as needed */
  }

  .kl {
    margin-left: auto;
  }

  #GenderPayGap input[type="checkbox"],
  #GenderPayGap img {
    /* Adjust input and image styles for smaller screens */
    max-width: 100%;
    height: auto;
    display: block;
    /* Ensure they occupy full width available */
    margin: 10px 0;
    /* Add some spacing for better visibility */
  }

  /* Additional responsive styles as needed */
}

/* --------------------------------ModernSlaveryStatement----------------- */
@media only screen and (max-width: 600px) {

  /* Adjust styles for smaller screens */
  #ModernSlaveryStatement {
    /* For example, you can modify the layout or width of the content */
    width: 100%;
    /* Ensure it takes the full width of the screen */
  }

  .kh {
    width: 50%;
  }

  #ModernSlaveryStatement h1 {
    /* Adjust heading styles for smaller screens */
    font-size: 24px;
    /* Modify font size as needed */
  }

  .kl {
    margin-left: auto;
  }

  #ModernSlaveryStatement input[type="checkbox"],
  #ModernSlaveryStatement img {
    /* Adjust input and image styles for smaller screens */
    max-width: 100%;
    height: auto;
    display: block;
    /* Ensure they occupy full width available */
    margin: 10px 0;
    /* Add some spacing for better visibility */
  }

  /* Additional responsive styles as needed */
}

/* --------------------------------OurCOVID-19Response----------------- */
@media only screen and (max-width: 600px) {

  /* Adjust styles for smaller screens */
  #OurCOVID-19Response {
    /* For example, you can modify the layout or width of the content */
    width: 100%;
    /* Ensure it takes the full width of the screen */
  }

  .kh {
    width: 50%;
  }

  #OurCOVID-19Response h1 {
    /* Adjust heading styles for smaller screens */
    font-size: 24px;
    /* Modify font size as needed */
  }

  .kl {
    margin-left: auto;
  }

  #OurCOVID-19Response input[type="checkbox"],
  #OurCOVID-19Response img {
    /* Adjust input and image styles for smaller screens */
    max-width: 100%;
    height: auto;
    display: block;
    /* Ensure they occupy full width available */
    margin: 10px 0;
    /* Add some spacing for better visibility */
  }

  /* Additional responsive styles as needed */
}

/* --------------------------------CounterfeitProductsPolicy----------------- */
@media only screen and (max-width: 600px) {

  /* Adjust styles for smaller screens */
  #CounterfeitProductsPolicy {
    /* For example, you can modify the layout or width of the content */
    width: 100%;
    /* Ensure it takes the full width of the screen */
  }

  .kh {
    width: 50%;
  }

  #CounterfeitProductsPolicy h1 {
    /* Adjust heading styles for smaller screens */
    font-size: 24px;
    /* Modify font size as needed */
  }

  .kl {
    margin-left: auto;
  }

  #CounterfeitProductsPolicy input[type="checkbox"],
  #CounterfeitProductsPolicy img {
    /* Adjust input and image styles for smaller screens */
    max-width: 100%;
    height: auto;
    display: block;
    /* Ensure they occupy full width available */
    margin: 10px 0;
    /* Add some spacing for better visibility */
  }

  /* Additional responsive styles as needed */
}

/* --------------------------------about----------------- */
@media only screen and (max-width: 600px) {

  /* Adjust styles for smaller screens */
  #about {
    /* For example, you can modify the layout or width of the content */
    width: 100%;
    /* Ensure it takes the full width of the screen */
  }

  .kh {
    width: 50%;
  }

  #about h1 {
    /* Adjust heading styles for smaller screens */
    font-size: 24px;
    /* Modify font size as needed */
  }

  .kl {
    margin-left: auto;
  }

  #about input[type="checkbox"],
  #about img {
    /* Adjust input and image styles for smaller screens */
    max-width: 100%;
    height: auto;
    display: block;
    /* Ensure they occupy full width available */
    margin: 10px 0;
    /* Add some spacing for better visibility */
  }

  /* Additional responsive styles as needed */
}


.kl {
  margin-left: 250px;
}

.menu:hover>i {
  opacity: 1;
}

/* Translate the arrow logo right on click */
.menu:active>i {
  margin-left: 10px;
}




body {
  /*   Add imported font */
  font-family: 'DM Sans', sans-serif;

  /*   Set colors for checkboxes, inputs, etc */
  accent-color: var(--main-col);
}

/* Style for website title */
.title {
  user-select: none;
  /* color: white; */
  padding: 20px;
}





.khuram {
  margin-left: 250px !important;
  margin-top: -570px;
}

/* ------------------------------- */


@media (max-width: 768px) {
  .khuram {
    margin-left: 0 !important;
    margin-top: 0px;
  }

  .allpage {
    margin-top: 60PX;
  }

  .allpage .about-link {
    position: static;
    /* Reset position */
    margin-left: auto;
    /* Set margin-left to auto to push to the right */
    display: block;
    /* Ensure it's a block-level element */
    text-align: right;
    /* Align the content to the right */

  }

  @media (max-width: 576px) {
    .ml-5-sm {
      margin-left: 0 !important;
    }

    .col-md-12.ml-5 {
      margin-left: 0 !important;
    }

    .img-fluid {
      width: 100px;
      /* Adjust image width for smaller screens */
    }

    .d-flex.kl {
      flex-direction: column;
      /* Stack buttons vertically on smaller screens */
      align-items: center;
      /* Center-align buttons */
    }

    .btn {
      margin-bottom: 5px;
      /* Add margin between buttons */
    }
  }

  .uk {
    margin-top: -50px;
    /* Example of setting a negative margin on the top side */
  }

  .ma {
    margin-top: 30px;
  }
}

/* Regular styles */

/* Media query for phones */
@media only screen and (max-width: 600px) {
  .image-container {
    /* Adjust styles for smaller screens */
    max-width: 100%;
    /* Optionally, limit the container's width on small screens */
  }


  .allpage {
    display: inline;

  }

  .allpage {
    width: 80%;
    /* Set the width of the container */
    max-width: 1200px;
    /* Optionally, set a maximum width */
    margin: 0 auto;
    /* Center the container horizontally */
  }


  /* Media query for smaller screens (phones) */
  @media screen and (max-width: 767px) {
    .btn {
      width: 100%;
      /* Take full width on smaller screens */
      font-size: 20px;
      /* Adjust font size for smaller screens */
      /* Any other adjustments for smaller screens */
    }
  }

  .image-container img {
    /* Adjust image styles for smaller screens */
    /* For example, you could remove the inline styles here if needed */
    max-width: 100%;
    height: auto;
  }

  /* Additional responsive styles as needed */
}

/* Default height for the card */
/* .card {
  height: 380px;
} */

/* Media query for phones (adjust the max-width as needed) */
@media screen and (max-width: 767px) {
  .card {
    height: auto;
    /* or height: unset; to remove the specified height */
  }
}

/* Default styles */




.btnSubmit {
  border: none;
  border-radius: 1.5rem;
  padding: 1%;
  width: 100%;
  cursor: pointer;
  background: #000000;
  color: #fff;
}

label {
  font-size: 17px;
  font-weight: bold;
}

/* Media query for smaller screens (phones) */
@media (max-width: 576px) {
  .form-content {
    width: 90%;
    /* Adjust width for smaller screens */
    padding: 5%;
    /* Increase padding for better spacing on smaller screens */
    font-size: 14px;
    /* Decrease font size for better readability */
    margin-left: 20px;
    margin-top: 90px;
  }

  .form-control {
    /* Adjust any other necessary styles for form controls */
  }

  .btnSubmit {
    /* Adjust any other necessary styles for the submit button */
  }

  label {
    font-size: 14px;
    /* Decrease label font size for smaller screens */
  }
}

.custom-width {
  width: 300px;
  /* Set your desired width */
}

/* Add media query for phones */
@media (max-width: 767px) {
  .register-form .form-group {
    margin-bottom: 15px;
  }

  .register-form .custom-width {
    width: 100%;
    /* Adjust button width for smaller screens */
  }
}