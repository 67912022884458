.delivery-options {
  display: flex;
  flex-direction: column;
  margin-left: 20px; /* Adjust margin as needed */
}

.radio-option {
  margin-bottom: 10px; /* Adjust spacing between options */
}




.lor{
    background-color: rgb(255, 255, 255);
    border-radius: 30px; /* Adjust this value to change the roundness of the corners */
    width: 708%;
    /* margin-left: 1px; */
}

.icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
}

.icon-sm {
    width: 2rem;
    height: 2rem;
    

}
.tt{
    border-color: linear-gradient(red, green) !important;
    border-radius: 10px;
}

.order-card{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    
}